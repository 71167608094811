import React from 'react';
import { format, formatDistance, differenceInDays } from 'date-fns';
import { Tooltip, Link, Box } from '@mui/material';
import AddBannedTerm from '@components/AddBannedTerm';
import { formatPrice } from '@utils/index';

export interface SeeStatsContentProps {
  ASIN: string;
  maxHeight?: number;
}

export interface Column {
  id: 'name' | 'data';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

export const createRows = (dealSummaryAdmin: any) => {
  if (!dealSummaryAdmin) return [];

  const daysAgo = (date: Date): number => {
    return differenceInDays(new Date(), new Date(date));
  };
  const formatDateTooltip = (date: Date) => (
    <Tooltip
      title={`${format(
        new Date(date),
        'EEEE LLL do, yyyy hh:mm a z'
      )} (${daysAgo(date)}) days ago`}
    >
      <span>
        {formatDistance(new Date(date), new Date(), { addSuffix: true })}
      </span>
    </Tooltip>
  );

  return [
    {
      name: 'ASIN',
      data: dealSummaryAdmin?.ASIN || '--'
    },
    {
      name: 'Date added',
      data: dealSummaryAdmin.dateAdded
        ? formatDateTooltip(dealSummaryAdmin.dateAdded)
        : 'missing'
    },
    {
      name: 'Date first active',
      data: dealSummaryAdmin.dateFirstActive
        ? formatDateTooltip(dealSummaryAdmin.dateFirstActive)
        : '--'
    },
    {
      name: 'Last checked',
      data: dealSummaryAdmin.lastChecked
        ? formatDateTooltip(dealSummaryAdmin.lastChecked)
        : '--'
    },
    {
      name: 'Date last sold',
      data: dealSummaryAdmin.dateLastSold
        ? formatDateTooltip(dealSummaryAdmin.dateLastSold)
        : '--'
    },
    {
      name: 'Total sold',
      data: dealSummaryAdmin.totalSold || 0
    },
    {
      name: 'Total sales items',
      data: `${dealSummaryAdmin.totalSalesItems || 0} ${
        dealSummaryAdmin?.totalSalesItems
          ? `(${(
              dealSummaryAdmin?.totalSold / dealSummaryAdmin?.totalSalesItems
            ).toFixed(2)} / per transaction avg)`
          : ''
      }`
    },
    {
      name: 'Extra deal',
      data: (
        <Box>
          {dealSummaryAdmin?.extraDeal ? (
            <span>
              {dealSummaryAdmin.extraDealLink ? (
                <Link
                  href={dealSummaryAdmin.extraDealLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {dealSummaryAdmin.extraDeal}
                </Link>
              ) : (
                dealSummaryAdmin.extraDeal
              )}
            </span>
          ) : (
            '--'
          )}
        </Box>
      )
    },
    {
      name: 'Name',
      data: dealSummaryAdmin.title
    },
    {
      name: 'Brand',
      data: (
        <span>
          {dealSummaryAdmin?.brand || '--'}
          {dealSummaryAdmin.brand ? (
            <AddBannedTerm
              newTermCreate={{
                term: (dealSummaryAdmin.brand || '').toLowerCase(),
                type: 'BANNED_DOTD_EMAIL_TITLE_BRAND'
              }}
              blockingLabel="Block DOTD email title brand"
              editingLabel="Edit Blocked DOTD email title brand"
            />
          ) : null}
          {dealSummaryAdmin.brand ? (
            <AddBannedTerm
              newTermCreate={{
                term: (dealSummaryAdmin.brand || '').toLowerCase(),
                type: 'BANNED_SIMILAR_BRANDS'
              }}
              blockingLabel="Block from 'more from this brand' section as brand is similar"
              editingLabel="Edit Blocked brand"
            />
          ) : null}
        </span>
      )
    },
    {
      name: 'Category',
      data: dealSummaryAdmin?.category || '--'
    },
    {
      name: 'Secondary Category',
      data: dealSummaryAdmin?.secondCategory || '--'
    },
    {
      name: 'Strategy Id(s)',
      data: dealSummaryAdmin?.strategyId || '--'
    },
    {
      name: 'Listing mode',
      data: dealSummaryAdmin?.listingMode || '--'
    },

    {
      name: 'Average price sold',
      data: dealSummaryAdmin.averagePrice
        ? `${formatPrice(dealSummaryAdmin.averagePrice || 0)} ${
            dealSummaryAdmin.finalPrice < dealSummaryAdmin.averagePrice &&
            dealSummaryAdmin.finalPrice > 0
              ? `   --  ✅ 🔼 ${(
                  ((dealSummaryAdmin.averagePrice -
                    dealSummaryAdmin.finalPrice) /
                    dealSummaryAdmin.averagePrice) *
                  100
                ).toFixed(2)}% more than final price`
              : ''
          }`
        : '--'
    },
    {
      name: 'Final price',
      data: `${formatPrice(dealSummaryAdmin.finalPrice || 0)} ${
        dealSummaryAdmin.finalPrice < dealSummaryAdmin.averagePrice &&
        dealSummaryAdmin.finalPrice > 0
          ? `   --  ✅ 🔽 ${(
              ((dealSummaryAdmin.averagePrice - dealSummaryAdmin.finalPrice) /
                dealSummaryAdmin.averagePrice) *
              100
            ).toFixed(2)}% lower than average price sold`
          : ''
      }`
    },
    {
      name: 'Deal threshold',
      data: formatPrice(dealSummaryAdmin.dealThreshold || 0)
    }
  ];
};
